import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Cards from "./CARDS"


const Download = () => {
    return (
        <div>
            <Cards/>
        </div>

    );
};


export default Download;